import React, { useEffect } from "react";
import { WindupChildren,Pace } from "windups";

import Aos from "aos";
import "aos/dist/aos.css";


const Home = () => {
 

  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Banner  */}
      <div className="top-banner">
      <section className="banner" id="home">
        <div className="container sm-flex" data-aos="fade-up">
        <div className="row align-items-center justify-content-end mt-lg-5">
            <div className="col-md-5 z-1 mb-3" >
              <img src="assets/ss.png" className="w-100 rounded-3" alt="" />
            </div>
            </div>
          <div className="row mt-4 mt-lg-0">
            <div className="col-lg-10 z-1">
              <h1 className="mb-2">Building a creator-first #SocialFi project.</h1>
              <h1 className="mb-2">Influencers launch keys to bond with communities.</h1>
              <h1 className="mb-2">Low fees for all</h1>
            </div>
            </div>
        </div>

            <div className="social-icons">
              <a href="https://twitter.com/onlyfrens_cc" target={"_blank"} rel="noreferrer"><img src="assets/x-ic.png" alt="" /></a>
              {/* <a href="https://t.me/OnlyFrens_cc" target={"_blank"} rel="noreferrer"><img src="assets/tg-ic.png" alt="" /></a> */}
            <a href="https://onlyfrens.gitbook.io/onlyfrens/" target={"_blank"} rel="noreferrer"><img src="assets/doc.png" alt="" /></a>
            </div>

      </section>
      </div>

      <section>
        <div className="container pb-5">
          <div className="row align-items-center text-center mt-5 py-5">
            <div className="col-lg-12 z-1" data-aos="fade-up">
              <span className="primary-badge"> Features </span>
            </div>
          </div>

          <div className="row align-items-center justify-content-evenly text-center mt-5 pt-5">
            <div className="col-lg-4 col-10 mx-md-0 mx-auto z-1">
             <div className="box-ic">
              <div className="ic-img"><img src="assets/ic-1.png" alt="" /></div>
              <div className="text">Lower Fees for Traders</div>
             </div>
            </div>
            <div className="col-lg-4 col-10 mx-md-0 mx-auto z-1">
             <div className="box-ic">
              <div className="ic-img"><img src="assets/ic-2.png" alt="" /></div>
              <div className="text">Maxium Composability With ERC20 Standard For Keys</div>
             </div>
            </div>
            <div className="col-lg-4 col-10 mx-md-0 mx-auto z-1">
             <div className="box-ic">
              <div className="ic-img"><img src="assets/ic-3.png" alt="" /></div>
              <div className="text">Creator-First Ecosystem</div>
             </div>
            </div>
            <div className="col-lg-4 col-10 mx-md-0 mx-auto z-1">
             <div className="box-ic">
              <div className="ic-img"><img src="assets/ic-4.png" alt="" /></div>
              <div className="text">Low Slippage</div>
             </div>
            </div>
            <div className="col-lg-4 col-10 mx-md-0 mx-auto z-1">
             <div className="box-ic">
              <div className="ic-img"><img src="assets/ic-5.png" alt="" /></div>
              <div className="text">Integrated DEX For a Seamless Trading Experience</div>
             </div>
            </div>
            <div className="col-lg-4 col-10 mx-md-0 mx-auto z-1">
             <div className="box-ic">
              <div className="ic-img"><img src="assets/ic-6.png" alt="" /></div>
              <div className="text">No Signup Required</div>
             </div>
            </div>
            <div className="col-lg-4 col-10 mx-md-0 mx-auto z-1">
             <div className="box-ic">
              <div className="ic-img"><img src="assets/ic-7.png" alt="" /></div>
              <div className="text">Better UI / UX</div>
             </div>
            </div>
            <div className="col-lg-4 col-10 mx-md-0 mx-auto z-1">
             <div className="box-ic">
              <div className="ic-img"><img src="assets/ic-8.png" alt="" /></div>
              <div className="text">Works with Mobile/Desktop</div>
             </div>
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
};

export default Home;
