import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const Header = () => {
  const [stickyClass, setStickyClass] = useState("relative");
  const homeNav = () => {
    if (document.body.classList.contains("home-sm")) {
      document.body.classList.remove("home-sm");
    } else {
      document.body.classList.add("home-sm");
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50
        ? setStickyClass("bg-black")
        : setStickyClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div>
      <nav className={`navbar navbar-expand-lg transition-all py-3 ${stickyClass}`}>
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="assets/logo.png" height={50} alt="" />
          </Link>
          <div className="d-md-none d-inline-flex gap-2 align-items-center">
          <a href="javascript:void(0)" className="launch shadow" rel="noreferrer">
              Launch App <span>(Comming Soon)</span>
              </a>
            {/* <button className="navbar-toggler" type="button" onClick={() => {homeNav();}}>
              <span className="iconify" data-icon="humbleicons:bars"></span>
            </button> */}
          </div>

          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <div className="d-md-none d-flex justify-content-between text-end p-4">
              <Link to="/" className="navbar-brand">
                <img src="assets/logo.png" alt="" />
              </Link>
              <a href="javascript:void(0)" onClick={() => {homeNav();}} className="cross-btn text-white">
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            {/* <ul className="navbar-nav align-items-center mx-auto px-4 px-lg-0">
                <li className="nav-item"><Link to={"/"} className="nav-link" onClick={() => { homeNav(); }}>Home</Link></li>
                <li className="nav-item"><Link to={""} className="nav-link" onClick={() => { homeNav(); }}>Liquidity</Link></li>
                <li className="nav-item"><Link to={""} className="nav-link" onClick={() => { homeNav(); }}> Farm </Link></li>
                <li className="nav-item"><Link to={""} className="nav-link" onClick={() => { homeNav(); }}>Earn </Link></li>
                <li className="nav-item"><Link to={""} className="nav-link" onClick={() => { homeNav(); }}> Bridge </Link></li>
            </ul> */}
            <div className="d-lg-flex gap-5 px-4 px-lg-0 position-relative">
            <a href="javascript:void(0)" className="launch shadow" rel="noreferrer">
              Launch App <span>(Coming Soon)</span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
